export default class EditDocumentConstants {
  static COLORED_BLOCKS = [
    "documentType",
    "originalDocumentId",
    "currency",
    "amountBase",
    "amountVat",
    "amount",
    "issueDate",
    "dueDate",
    "address"
  ];

  static COLOR_SET = [
    "#386df4",
    "#36b37e",
    "#00c7e6",
    "#491fe0",
    "#ffab00",
    "#9c27b0",
    "#c878e6"
  ];

  static FIELD_COLORS = EditDocumentConstants.COLORED_BLOCKS
    .reduce((result, field, index) => ({
      ...result, [field]: EditDocumentConstants.COLOR_SET[index % EditDocumentConstants.COLOR_SET.length]
    }), {});
}
