import Css from "./style.module.scss";

import Content from "./Content";
import FormContext from "contexts/FormContext";
import Provider from "./Provider";
import React, { useCallback, useContext, useRef } from "react";

const EditForm = ({ disabled, documentData, documentFrozen, openPairedTransactionsWindow }) => {
  const rootRef = useRef(null);

  const { values, setState } = useContext(FormContext);

  const handleDocumentChange = useCallback((update) => {
    setState((state) => {
      const newValues = update(state.values);

      return { ...state, values: { ...state.values, ...newValues } };
    });
  }, [setState]);

  const { attachment } = documentData || {};

  return (
    <div className={Css.container}>
      <div ref={rootRef} className={Css.documentLabeler}>
        <Provider
          rootRef={rootRef}
          state={values}
          disabled={disabled}
          attachment={attachment}
          documentData={documentData}
          recogniseData={documentData.recogniseData}
          documentFrozen={documentFrozen}
          onChange={handleDocumentChange}
          onPairedTransactionClick={openPairedTransactionsWindow} >
          <Content disabled={disabled} />
        </Provider>
      </div>
    </div>
  );
};

export default React.memo(EditForm);
