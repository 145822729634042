import Css from "./style.module.scss";

import dropBox from "assets/dropBox.svg";

import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import DropboxChooser from "react-dropbox-chooser";
import React, { useCallback } from "react";
import Utils from "utils/Utils";

const SERVICE_NAME = "Dropbox";

const FILE_DROP_MIME_TYPES = Object.values(Constants.DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions, mimeType }) => [...aggregator, ...extensions, mimeType], []);

const DropBoxPicker = ({ onFilesPicked }) => {
  const { DROPBOX_APP_KEY } = Utils.getProcessEnvVars();

  const successCallback = useCallback((files) => {
    const result = files.map((file) => {
      const extension = `.${file.link.split(".").pop().toLowerCase()}`;

      const { mimeType } = Object.values(Constants.DOCUMENT_FILE_TYPES).find(({ extensions }) => extensions.includes(extension));

      return { ...file, mimeType };
    });

    onFilesPicked(result);
  }, [onFilesPicked]);

  return (
    <DropboxChooser
      multiselect
      linkType="direct"
      appKey={DROPBOX_APP_KEY}
      success={successCallback}
      extensions={FILE_DROP_MIME_TYPES}>
      <Button className={Css.dropBoxPicker}>
        <img src={dropBox} alt={SERVICE_NAME} title={SERVICE_NAME} />
        <span>{SERVICE_NAME}</span>
      </Button>
    </DropboxChooser>
  );
};

export default React.memo(DropBoxPicker);
