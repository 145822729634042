import Css from "./style.module.scss";

import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { useSelector } from "react-redux";
import ColoredBlock from "./lib/ColoredBlock";
import DataConstants from "const/DataConstants";
import DragRectangle from "./lib/DragRectangle";
import EditDocumentUtils from "utils/EditDocument";
import React, { useCallback, useMemo, useRef, useState } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";

const checkTextOrCheckBoxBlock = (block) => block.sourceFieldId !== undefined;

const getFilteredUnHighlightedBlocks = (selectedField, coloredBlocks, wordBlocks) => {
  const textFieldSelected = selectedField;

  const filteredColoredBlocks = coloredBlocks.filter((block) => {
    if (textFieldSelected) {
      const fieldId = checkTextOrCheckBoxBlock(block)
        ? block.sourceFieldId
        : block.tableId;

      return fieldId === selectedField?.id;
    }

    return true;
  });

  return wordBlocks
    .filter(
      (block) =>
        !filteredColoredBlocks.find(
          (coloredBlock) => coloredBlock.block.id === block.id
        )
    );
};

const convertEventCoordsToRefSpace = (ref, evt) => {
  const refCoords = ref.current
    ? ref.current.getBoundingClientRect()
    : ({ height: 0, width: 0, x: 0, y: 0 });

  return {
    x: evt.clientX - refCoords.x,
    y: evt.clientY - refCoords.y
  };
};

const coordinateIsInBlock = (coordinate, block) => {
  const scaledBlock = {
    left: block.left,
    width: block.width,
    top: block.top,
    height: block.height
  };

  const xCoordIsValid
    = coordinate.x >= scaledBlock.left
    && coordinate.x <= scaledBlock.left + scaledBlock.width;

  const yCoordIsValid
    = coordinate.y >= scaledBlock.top
    && coordinate.y <= scaledBlock.top + scaledBlock.height;

  return xCoordIsValid && yCoordIsValid;
};

const getBlocksUnderMouse = (blocks, mouseCoordinates, docPageHeights, docRenderWidth) => {
  const block = blocks.findLast((item) =>
    coordinateIsInBlock(
      mouseCoordinates,
      EditDocumentUtils.getBlockRenderCoords(item.boundingBox, docPageHeights, docRenderWidth)
    ));

  return block ? [block] : [];
};

const convertBoundingBoxToRectCoords = (boundingBox) => ({
  start: { x: boundingBox.left, y: boundingBox.top },
  end: {
    x: boundingBox.left + boundingBox.width,
    y: boundingBox.top + boundingBox.height
  }
});

const doRectanglesIntersect = (rectA, rectB) => {
  const minMaxA = EditDocumentUtils.toMinMax(rectA);

  const minMaxB = EditDocumentUtils.toMinMax(rectB);

  const aLeftOfB = minMaxA.maxX < minMaxB.minX;

  const aRightOfB = minMaxA.minX > minMaxB.maxX;

  const aBelowB = minMaxA.minY > minMaxB.maxY;

  const aAboveB = minMaxA.maxY < minMaxB.minY;

  return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
};

const getWordBlocksInsideRectangle = (rectCoords, wordBlocks, docPageHeights, docRenderWidth) => {
  return wordBlocks.filter((block) => {
    if (block.type !== "word") return false;

    const blockRenderCoords = EditDocumentUtils.getBlockRenderCoords(
      block.boundingBox,
      docPageHeights,
      docRenderWidth
    );

    return doRectanglesIntersect(
      rectCoords,
      convertBoundingBoxToRectCoords(blockRenderCoords)
    );
  });
};

export const DocumentBlockLayer = ({ width, pagesData, docPageHeights, scrollContainerRef, wordBlocks }) => {
  const {
    DOCUMENT_TYPES: { RECEIPT },
    ADVANCED_TRANSACTION_TYPES: { EXPENSE }
  } = DataConstants;

  const state = useDocumentContext();

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const {
    ocrMeta,
    documentFrozen,
    setValueFromDocument,
    recogniseData: { confidence },
    localState: { activeField, hoveredField },
    documentState: { type, exportAs }
  } = state;

  const showDueDate = (!quickBooksBusiness || type !== RECEIPT || exportAs !== EXPENSE);

  const edited = !confidence;

  const blockRootRef = useRef(null);

  const [unHighlightedBlocksToDisplay, setUnHighlightedBlocksToDisplay] = useState([]);

  const [dragRectangle, setDragCoords] = useState();

  const onDragStart = useCallback((startCoords) => {
    setDragCoords({
      start: startCoords,
      end: startCoords
    });
  }, []);

  const onDragEnd = useCallback(() => {
    setDragCoords(undefined);
  }, []);

  const onDrag = useCallback((newMouseCoords) => {
    setDragCoords((curDragCoords) => {
      if (!curDragCoords) {
        throw new Error("Invalid state reached. No drag in progress");
      }

      return {
        ...curDragCoords,
        end: newMouseCoords
      };
    });
  }, []);

  const dragging = dragRectangle !== undefined;

  const selectedFieldColor = useMemo(() => {
    return activeField ? EditDocumentUtils.getColorFromFieldId(activeField.id) : null;
  }, [activeField]);

  const coloredBlocks = useMemo(() => {
    if (!width) return [];

    return EditDocumentUtils.getColoredBlocks(ocrMeta, { showDueDate }).map((item) => {
      if (!docPageHeights[item.block.boundingBox.page]) return item;

      return {
        ...item,
        block: {
          ...item.block,
          boundingBox: EditDocumentUtils.rotateBoundingBox({
            boundingBox: item.block.boundingBox,
            angle: Utils.normalizeAngle(pagesData?.[item.block.boundingBox.page]?.angle || 0),
            width: width || 0,
            height: (docPageHeights[item.block.boundingBox.page] || 0)
          })
        }
      };
    });
  }, [docPageHeights, ocrMeta, pagesData, width, showDueDate]);

  const coloredBlocksToDisplay = useMemo(() => {
    return edited ? [] : EditDocumentUtils.getColoredBlocksToDisplay(coloredBlocks, activeField, hoveredField);
  }, [edited, coloredBlocks, activeField, hoveredField]);

  const filteredUnHighlightedBlocks = useMemo(() => {
    return getFilteredUnHighlightedBlocks(
      activeField,
      coloredBlocks,
      wordBlocks
    );
  }, [activeField, coloredBlocks, wordBlocks]);

  const handleOnMouseDown = useCallback((event) => {
    const convertedMouseCoords = convertEventCoordsToRefSpace(blockRootRef, event);

    const hoveringOnWordBlock = unHighlightedBlocksToDisplay.length !== 0;

    const hoveringOnFieldBlock = getBlocksUnderMouse(
      coloredBlocks.map((block) => block.block),
      convertedMouseCoords,
      docPageHeights,
      width
    ).length !== 0;

    if (
      !dragging
      && !hoveringOnWordBlock
      && !hoveringOnFieldBlock
    ) {
      onDragStart(convertedMouseCoords);
    }
  }, [
    coloredBlocks,
    docPageHeights,
    dragging,
    unHighlightedBlocksToDisplay.length,
    width,
    onDragStart
  ]);

  const handleOnMouseUp = useCallback(() => {
    if (dragging) onDragEnd();

    if (documentFrozen) return;

    if (unHighlightedBlocksToDisplay.length > 0) {
      if (activeField && activeField.selectable) {
        setValueFromDocument(activeField.id, unHighlightedBlocksToDisplay.map((item) => item.text.trim()).join(" "));
      }
      setUnHighlightedBlocksToDisplay([]);
    }
  }, [dragging, onDragEnd, documentFrozen, unHighlightedBlocksToDisplay, activeField, setValueFromDocument]);

  const handleOnMouseMove = useCallback((event) => {
    const newMouseCoords = convertEventCoordsToRefSpace(blockRootRef, event);

    if (dragging) onDrag(newMouseCoords);

    const newBlocksToDisplay = dragging
      ? getWordBlocksInsideRectangle(
        dragRectangle,
        filteredUnHighlightedBlocks,
        docPageHeights,
        width
      )
      : getBlocksUnderMouse(
        filteredUnHighlightedBlocks,
        newMouseCoords,
        docPageHeights,
        width
      );

    if (
      newBlocksToDisplay.length > 0
      || newBlocksToDisplay.length !== unHighlightedBlocksToDisplay.length
    ) {
      setUnHighlightedBlocksToDisplay(newBlocksToDisplay);
    }
  }, [
    docPageHeights,
    dragRectangle,
    dragging,
    filteredUnHighlightedBlocks,
    onDrag,
    unHighlightedBlocksToDisplay.length,
    width
  ]);

  return (
    <div
      ref={blockRootRef}
      className={Css.documentBlockLayer}
      onMouseDown={handleOnMouseDown}
      onMouseMove={handleOnMouseMove}
      onMouseUp={handleOnMouseUp}>
      {coloredBlocksToDisplay.map((coloredBlock, index) => (
        <ColoredBlock
          key={coloredBlock.block.id}
          block={coloredBlock.block}
          color={coloredBlock.color}
          docPageHeights={docPageHeights}
          docRenderWidth={width}
          autoScroll={index === 0}
          coloredBlock={coloredBlock}
          scrollContainerRef={scrollContainerRef} />
      ))}
      {!!activeField && activeField.selectable && selectedFieldColor && unHighlightedBlocksToDisplay.map((block) => (
        <ColoredBlock
          key={block.id}
          block={block}
          color={selectedFieldColor}
          docPageHeights={docPageHeights}
          docRenderWidth={width} />
      ))}
      {dragRectangle && !!activeField && selectedFieldColor && (
        <DragRectangle
          dragCoords={dragRectangle}
          bgColor={selectedFieldColor} />
      )}
    </div>
  );
};
