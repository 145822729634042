/* eslint-disable no-magic-numbers */
import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { FieldsPanel } from "./lib/FieldsPanel";
import { LineItems } from "./lib/LineItems";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import DocumentControlPanel from "./lib/DocumentControlPanel";
import DocumentRenderer from "./lib/DocumentRenderer";
import EditDocumentConstants from "const/EditDocumentConstants";
import EditDocumentUtils from "utils/EditDocument";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";
import useWindowResize from "hooks/useWindowResize";

const { FIELD_COLORS } = EditDocumentConstants;

const WINDOW_RESIZE_DEBOUNCE = 300;

const LINE_WIDTH = 2;

const FIELDS_OVERLAP = 2;

const SCROLL_WIDTH = 24;

const ROTATE_RIGHT = 90;

const ROTATE_LEFT = 270;

const FLIP_DIMENSIONS_ANGLE = [ROTATE_LEFT, ROTATE_RIGHT];

const PATH_INITIAL_DATA = { left: 0, top: 0, width: 0, height: 0, revert: false };

const getPathData = (fieldToHighlight, container) => {
  const [activeFieldId] = fieldToHighlight.id?.split(".");

  const blockNode = document.querySelector(`[data-block="${activeFieldId}"]`);

  const containerBounding = container.getBoundingClientRect();

  if (!blockNode) return PATH_INITIAL_DATA;

  const blockBounding = blockNode.getBoundingClientRect();

  const fieldNode = document.querySelector(`[data-field="${activeFieldId}"]`);

  if (!fieldNode) return PATH_INITIAL_DATA;

  const fieldBounding = fieldNode.getBoundingClientRect();

  const blockMiddleTop = blockBounding.top + (blockBounding.height / 2);

  const fieldMiddleTop = fieldBounding.top + (fieldBounding.height / 2);

  const svgTopTemp = Math.min(blockMiddleTop, fieldMiddleTop) - LINE_WIDTH;

  return {
    left: blockBounding.right - containerBounding.left,
    top: svgTopTemp - containerBounding.top,
    width: fieldBounding.left - blockBounding.right + LINE_WIDTH + FIELDS_OVERLAP,
    height: Math.max(blockMiddleTop, fieldMiddleTop) - svgTopTemp + LINE_WIDTH,
    revert: blockMiddleTop > fieldMiddleTop
  };
};

const getPath = (width, height, revert, top, left) => {
  if (!width || !height) return "";

  const [xA, yA, xB, yB] = revert
    ? [left, top + height, left + width, top]
    : [left, top, left + width, top + height];

  return [
    `M${xA},${yA}`,
    `Q${xA + ((xB - xA) / 4)},${yA} ${xA + ((xB - xA) / 2)},${yA + ((yB - yA) / 2)}`,
    `Q${xB - ((xB - xA) / 4)},${yB} ${xB},${yB}`
  ].join(" ");
};

const Content = ({ disabled }) => {
  const {
    DOCUMENT_TYPES: { RECEIPT },
    ADVANCED_TRANSACTION_TYPES: { EXPENSE }
  } = DataConstants;

  const scrollContainerRef = useRef();

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const [pathData, setPathData] = useState(PATH_INITIAL_DATA);

  const [pagesData, setPagesData] = useState([]);

  const [numPages, setNumPages] = useState(null);

  const [documentScale, setDocumentScale] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [fullScreenMode, setFullScreenMode] = useState(false);

  const [containerRect, setContainerRect] = useState({ width: 0, height: 0 });

  const {
    ocrMeta,
    localState: { activeField, hoveredField },
    documentState: { type, exportAs }
  } = useDocumentContext();

  const showDueDate = (!quickBooksBusiness || type !== RECEIPT || exportAs !== EXPENSE);

  const coloredBlocks = useMemo(() => {
    return EditDocumentUtils.getColoredBlocks(ocrMeta, { showDueDate });
  }, [ocrMeta, showDueDate]);

  const coloredBlocksToDisplay = useMemo(() => {
    return EditDocumentUtils.getColoredBlocksToDisplay(coloredBlocks, activeField, hoveredField);
  }, [coloredBlocks, activeField, hoveredField]);

  const fieldToHighlight = !!coloredBlocksToDisplay.length && hoveredField;

  const hoveredFieldColor = useMemo(() => {
    return FIELD_COLORS[fieldToHighlight?.id] || "#00adff99";
  }, [fieldToHighlight]);

  const updateSvg = useCallback(() => {
    setPathData(fieldToHighlight ? getPathData(fieldToHighlight, scrollContainerRef.current) : PATH_INITIAL_DATA);
  }, [fieldToHighlight]);

  const svgPathString = useMemo(() => {
    return getPath(pathData.width, pathData.height, pathData.revert, pathData.top, pathData.left);
  }, [pathData.height, pathData.left, pathData.revert, pathData.top, pathData.width]);

  const firstPageSided = FLIP_DIMENSIONS_ANGLE.includes(pagesData[0]?.rotate);

  const pageFitScale = pagesData[0] ? (
    (containerRect.height - SCROLL_WIDTH)
      / (firstPageSided ? pagesData[0].originalWidth : pagesData[0].originalHeight)
  ) : 0;

  const pageWidthScale = pagesData[0] ? (
    (containerRect.width - SCROLL_WIDTH)
      / (firstPageSided ? pagesData[0].originalHeight : pagesData[0].originalWidth)
  ) : 0;

  const checkScrolledPosition = useCallback(({ scrollTop, clientHeight }) => {
    let scrollMiddle = scrollTop + (clientHeight / 2);

    let index = 0;

    // eslint-disable-next-line no-loops/no-loops
    do {
      scrollMiddle -= (pagesData[index]?.height || 0) - 7.75;
      index++;
    } while (scrollMiddle > 0 && index < pagesData.length);

    setCurrentPage(index);
  }, [pagesData]);

  const handleWindowResize = useCallback(() => {
    const container = scrollContainerRef.current;

    if (container) {
      const rect = container.getBoundingClientRect();

      setDocumentScale(null);

      setContainerRect({
        top: rect.top,
        width: rect.width,
        height: rect.height
      });
    }
  }, [scrollContainerRef]);

  const handleWindowResizeDebounced = useMemo(() => {
    return Utils.debounce(handleWindowResize, WINDOW_RESIZE_DEBOUNCE);
  }, [handleWindowResize]);

  const checkScrolledPositionDebounced = useMemo(() => {
    return Utils.debounce(checkScrolledPosition, 300);
  }, [checkScrolledPosition]);

  const handlePanelsScroll = useCallback(({ target: { scrollTop, clientHeight } }) => {
    if (fieldToHighlight) {
      setPathData(getPathData(fieldToHighlight, scrollContainerRef.current));
    }
    checkScrolledPositionDebounced({ scrollTop, clientHeight });
  }, [checkScrolledPositionDebounced, fieldToHighlight]);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);

    const pagesHeight = pagesData
      .slice(0, newPage - 1)
      .map((page) => page.height)
      .reduce((aggregator, pageHeight) => aggregator + pageHeight + 7.75, 0);

    scrollContainerRef.current.scrollTop = pagesHeight;
  }, [pagesData]);

  const handleRequestFullSize = useCallback(async() => {
    if (scrollContainerRef.current.requestFullscreen) {
      try {
        await scrollContainerRef.current.requestFullscreen();

        setDocumentScale(null);

        setFullScreenMode(true);
      } catch (error) {}
    }
  }, []);

  const handleExitFullScreenClick = useCallback(async() => {
    if (document.fullscreenElement) {
      await document.exitFullscreen();

      setDocumentScale(null);
    }
  }, []);

  const handleFullScreenChange = useCallback(() => {
    setFullScreenMode(!!document.fullscreenElement);
  }, []);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [handleFullScreenChange]);

  useEffect(updateSvg, [updateSvg]);

  useWindowResize(updateSvg);

  useWindowResize(handleWindowResizeDebounced);

  useEffect(() => {
    handleWindowResize();
  }, [handleWindowResize]);

  return (
    <>
      <div className={Css.documentLabelerContent}>
        <div className={Css.documentWrapper}>
          {!!pagesData.length && (
            <DocumentControlPanel
              pageWidthScale={pageWidthScale}
              pagesCount={pagesData.length}
              currentPage={currentPage}
              documentScale={documentScale}
              setDocumentScale={setDocumentScale}
              onPageChange={handlePageChange}
              onRequestFullSize={handleRequestFullSize} />
          )}
          <div className={Css.documentContainer} ref={scrollContainerRef} onScroll={handlePanelsScroll}>
            <DocumentRenderer
              containerWidth={containerRect.width}
              firstPageSided={firstPageSided}
              pageWidthScale={pageWidthScale}
              pageFitScale={pageFitScale}
              pagesData={pagesData}
              scrollContainerRef={scrollContainerRef}
              documentScale={documentScale}
              numPages={numPages}
              setPagesData={setPagesData}
              setNumPages={setNumPages}
              setDocumentScale={setDocumentScale} />
            {!numPages && <Preloader absolute />}
            {fullScreenMode && (
              <Button
                className={Css.exitFullScreenButton}
                onClick={handleExitFullScreenClick}>
                <Icons.ArrowsIn />
              </Button>
            )}
            {disabled && <Preloader absolute />}
          </div>
          <svg
            width={containerRect.width}
            height={containerRect.height}
            pointerEvents="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            className={Css.svgPath}>
            <path fill="none" d={svgPathString} stroke={hoveredFieldColor} strokeWidth={LINE_WIDTH} />
          </svg>
        </div>
        <div className={Css.fieldsPanelContainer} onScroll={handlePanelsScroll}>
          <FieldsPanel disabled={disabled} />
        </div>
      </div>
      <LineItems />
    </>
  );
};

export default React.memo(Content);
