import Css from "./style.module.scss";

import EditDocumentUtils from "utils/EditDocument";
import React, { useMemo } from "react";

const SimpleBlock = (props) => {
  const {
    block,
    docPageHeights,
    docRenderWidth
  } = props;

  const coords = useMemo(() => {
    return EditDocumentUtils.getBlockRenderCoords(
      block.boundingBox,
      docPageHeights,
      docRenderWidth
    );
  }, [block.boundingBox, docPageHeights, docRenderWidth]);

  return (
    <div className={Css.simpleBlock} style={coords} />
  );
};

export default SimpleBlock;
